import * as React from 'react'
import { useState } from 'react'

import { renderers } from '@markdoc/markdoc'
import type { HeadersFunction, LoaderFunctionArgs } from '@remix-run/node'
import { Link, Outlet, useLoaderData, useMatches } from '@remix-run/react'
import cn from 'classnames'

import Details from '@/components/content/Details'
import { SearchButton } from '@/components/Search'
import SiteFooter from '@/components/SiteFooter'
import SiteHeader from '@/components/SiteHeader'
import { cacheControl } from '@/http.server'
import { findLp } from '@/models/lp.server'
import { pageHelper } from '@/request'
import type { Toc } from '@/utils/content.server'

type LoaderData = typeof loader

export async function loader({ request }: LoaderFunctionArgs) {
  const lp = findLp('docs')

  if (!lp) {
    throw new Response('Not Found', { status: 404 })
  }

  const { root } = pageHelper(request)

  return {
    lp: lp,
    root: root
  }
}

export const headers: HeadersFunction = () => ({
  'Cache-Control': cacheControl
})

export default function Docs() {
  const { lp } = useLoaderData<LoaderData>()
  const toc = useToc()

  const [isSideNavOpen, setIsSideNavOpen] = useState(false)
  const [isTocOpen, setIsTocOpen] = useState(false)

  return (
    <>
      <SiteHeader hideBanner={true} isFullWidth={true} />

      <main className='my-6 flex flex-col px-3 sm:px-5'>
        <section
          className={cn({
            '-mx-5 bg-gray-100/40 px-5 xl:!bg-primary dark:bg-gray-800/50': isSideNavOpen || isTocOpen
          })}
        >
          <div className='flex xl:flex-row xl:gap-x-3'>
            {lp.aside && (
              <div className={cn({ hidden: isTocOpen })}>
                <button
                  onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                  className={cn('absolute left-0 -mt-3 block bg-gray-100 px-sm xl:hidden dark:bg-gray-800', {
                    'inset-x-0 w-full text-left': isSideNavOpen
                  })}
                >
                  <span className={cn({ inline: !isSideNavOpen, hidden: isSideNavOpen })}>Documentation »</span>
                  <span className={cn('ml-4', { hidden: !isSideNavOpen, inline: isSideNavOpen })}>Close «</span>
                </button>

                <aside
                  className={cn(
                    'tree-nav sticky top-4 w-full shrink-0 space-y-3 overflow-y-auto overscroll-y-contain pb-3 pr-3 xl:w-32',
                    {
                      'block pt-3': isSideNavOpen,
                      'hidden h-[calc(100vh-112px)] xl:block': !isSideNavOpen
                    }
                  )}
                >
                  <SearchButton className='block w-full border bg-white px-1.5 py-1 text-left text-gray-500 dark:bg-black'>
                    Search…
                  </SearchButton>

                  {renderers.react(lp.aside, React, {
                    components: {
                      Details
                    }
                  })}
                </aside>
              </div>
            )}

            <div className='flex min-w-0 gap-x-3'>
              <article
                className={cn('mt-3 min-w-0 flex-1 xl:mt-0', {
                  'hidden xl:block': isSideNavOpen || isTocOpen
                })}
              >
                <Outlet />
              </article>

              <div className={cn({ hidden: isSideNavOpen })}>
                <button
                  onClick={() => setIsTocOpen(!isTocOpen)}
                  className={cn('absolute right-0 -mt-3 block bg-gray-100 px-sm lg:hidden dark:bg-gray-800', {
                    'inset-x-0 w-full text-right': isTocOpen
                  })}
                >
                  <span className={cn({ inline: !isTocOpen, hidden: isTocOpen })}>« Table of contents</span>
                  <span className={cn('mr-4', { hidden: !isTocOpen, inline: isTocOpen })}>Close »</span>
                </button>

                <aside
                  className={cn(
                    'sticky top-4 overflow-y-auto overscroll-y-contain py-3 pl-3 pr-1 lg:w-28 xl:w-32 xl:py-0',
                    {
                      'block pt-3': isTocOpen,
                      'hidden h-screen lg:block': !isTocOpen
                    }
                  )}
                >
                  {toc && (
                    <div>
                      <h4 className='text-secondary'>Table of contents</h4>

                      <ul>
                        {toc.map((item) => (
                          <li key={item.id}>
                            <Link className='font-semibold text-primary hover:text-blue' to={`#${item.id}`}>
                              {item.title}
                            </Link>

                            {item.children && item.children.length > 0 && (
                              <ul>
                                {item.children.map((child) => (
                                  <li key={child.id}>
                                    <Link className='text-primary hover:text-blue' to={`#${child.id}`}>
                                      {child.title}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </aside>
              </div>
            </div>
          </div>
        </section>
      </main>

      <SiteFooter isFullWidth={true} />
    </>
  )
}

function useToc() {
  const matches = useMatches()

  const doc = matches.find((match) => match.id === 'routes/docs.$' && 'data' in match) as {
    data: { doc: { toc: Toc[] } }
  }

  if (doc) {
    return doc.data?.doc?.toc
  }

  const lp = matches.find((match) => match.id === 'routes/docs._index' && 'data' in match) as {
    data: { lp: { toc: Toc[] } }
  }

  if (lp) {
    return lp.data?.lp?.toc
  }
}
